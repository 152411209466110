// third-party
import { FormattedMessage } from 'react-intl';

import { MusicPlaylist, MusicPlay, DirectboxReceive } from 'iconsax-react';

// icons
const icons = {
  artistsMenu: MusicPlay,
  songs: MusicPlaylist,
  proposals: DirectboxReceive
};

const stationsMenu = {
  id: 'group-stationsMenu',
  title: <FormattedMessage id="stationsMenu" />,
  icon: icons.stationsMenu,
  type: 'group',
  children: [
    {
      id: 'radioSongs',
      title: <FormattedMessage id="songs" />,
      type: 'item',
      url: '/radio-station/songs',
      icon: icons.songs
    },
    {
      id: 'proposals',
      title: <FormattedMessage id="proposals" />,
      type: 'item',
      url: '/radio-station/proposals',
      icon: icons.proposals
    },
    {
      id: 'listen',
      title: <FormattedMessage id="listen" />,
      type: 'item',
      url: '/radio-station/listen',
      icon: icons.proposals
    },
    {
      id: 'selected',
      title: <FormattedMessage id="selected" />,
      type: 'item',
      url: '/radio-station/selected',
      icon: icons.proposals
    }
  ]
};

export default stationsMenu;
