// third-party
import { FormattedMessage } from 'react-intl';

import { Settings, UserSquare } from 'iconsax-react';

// icons
const icons = {
  adminsMenu: Settings,
  // languages: Global,
  // musicGenres: Musicnote,
  artistIcon: UserSquare,
  roles: UserSquare
};

const settingsMenu = {
  id: 'group-settigsMenu',
  title: <FormattedMessage id="settings" />,
  icon: icons.adminsMenu,
  type: 'group',
  children: [
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'item',
      url: '/artist/profile/basic',
      icon: icons.artistIcon
    }
    // {
    //   id: 'settings',
    //   title: <FormattedMessage id="settings" />,
    //   type: 'item',
    //   url: '/manage/settings',
    //   icon: icons.languages
    // },
    // {
    //   id: 'billing',
    //   title: <FormattedMessage id="billing" />,
    //   type: 'item',
    //   url: '/manage/billing',
    //   icon: icons.musicGenres
    // }
  ]
};

export default settingsMenu;
