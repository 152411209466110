import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import adminsMenu from './admins-menu';
import artistsMenu from './artists-menu';
import settingsMenu from './settings-menu';
import stationsMenu from './stations-menu';
import agentsMenu from './agents-menu';

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = () => {
  const { user } = useContext(UserContext);
  let items = [settingsMenu];

  if (user) {
    switch (user.role) {
      case 'Admin':
        items = [adminsMenu];
        break;
      case 'Artist':
        items = [artistsMenu, settingsMenu];
        break;
      case 'Agent':
        items = [agentsMenu];
        break;
      case 'Station':
        items = [stationsMenu];
        break;
      default:
        items = [adminsMenu, artistsMenu, settingsMenu, stationsMenu];
    }
  } else {
    items = [adminsMenu, artistsMenu, settingsMenu, stationsMenu]; // Default items when no user is found
  }

  return {
    items
  };
};

export default MenuItems;
