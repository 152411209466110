// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proposal-card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.proposal-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.audio-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.audio-player {
  flex-grow: 1;
}

.audio-icons {
  display: flex;
  align-items: center;
}

.audio-icons .MuiIconButton-root {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/style.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;AAC7C;;AAEA;EACE,2BAA2B;EAC3B,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".proposal-card {\n  transition: transform 0.3s, box-shadow 0.3s;\n}\n\n.proposal-card:hover {\n  transform: translateY(-5px);\n  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);\n}\n\n.audio-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 10px;\n}\n\n.audio-player {\n  flex-grow: 1;\n}\n\n.audio-icons {\n  display: flex;\n  align-items: center;\n}\n\n.audio-icons .MuiIconButton-root {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
